const sectionFilters = [
  {
    type: "select",
    cols: 12,
    margin: true,
    showLabel: true,
    visible: true,
    label: "Select Module",
    placeholder: "",
    class: "font-small-3",
    model: null,
    options: [
      {
        value: 0,
        label: "All",
      },
    ],
    reduce: "value",
    selectText: "label",
  },
]

export { sectionFilters }
