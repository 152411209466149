<template>
  <b-modal
    size="md"
    ref="relationParagon"
    title="Relationships Sections"
    no-close-on-backdrop
    @hide="$emit('close')"
    centered
    content-class="modal-details"
    title-class="h3 text-white"
  >
    <ValidationObserver ref="form">
      <b-row>
        <b-col md="6">
          <ValidationProvider
            v-slot="{ errors }"
            name="section-user"
            rules="required"
          >
            <b-form-group label="Insert Section" label-for="input-1">
              <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                <template #prepend>
                  <div class="input-group-text">
                    <b-icon icon="box-seam" class="h4 m-0"> </b-icon>
                  </div>
                </template>
                <b-form-input
                  id="input-1"
                  v-model="section"
                  :state="errors.length > 0 ? false : null"
                  trim
                  :disabled="disabledSection"
                />
              </b-input-group>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="6">
          <b-form-group label="Search by module name" label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="moduleFilter"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <ValidationProvider
            v-slot="{ errors }"
            name="section-module"
            rules="required"
          >
            <b-form-group label="Select a module available">
              <b-form-checkbox-group
                v-model="selectedModules"
                :options="modulesOptionsFiltered"
                name="flavour-2a"
                class="available-modules"
                :state="errors.length > 0 ? false : null"
                stacked
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </ValidationObserver>
    <template #modal-footer>
      <div style="display: flex; justify-content: end; align-items: center">
        <b-button class="mr-2" @click="$emit('close')">Cancel</b-button>
        <b-button @click="insertRelationShip()" variant="primary">
          {{ isEdit ? "Edit relationship" : "Create relationship" }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ParagonService from "@/views/paragon/views/settings/service/paragon.service.js";
export default {
  props: {
    itemEdit: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      section: null,
      sections: [{ value: null, text: "Please select a user" }],
      moduleFilter: null,
      selectedModules: [], // Must be an array reference!
      modulesOptions: [],
    };
  },
  computed: {
    disabledSection() {
      return !!this.itemEdit;
    },
    isEdit() {
      return !!this.itemEdit;
    },
    modulesOptionsFiltered() {
      if (!this.moduleFilter) return this.modulesOptions;
      return this.modulesOptions.filter((module) =>
        module.text.toLowerCase().includes(this.moduleFilter.toLowerCase())
      );
    },
  },
  mounted() {
    this.toggleModal("relationParagon");
  },
  created() {
    this.getModulesAvailable();
  },
  methods: {
    async getModulesAvailable() {
      const response = await ParagonService.getModulesSection({
        section_id: this.itemEdit?.id || null,
      });
      const modules = response.modules;
      this.selectedModules = response.selectedModules;
      this.section = response.sectionName;
      this.modulesOptions = modules.map((module) => {
        return {
          text: module.name,
          value: module.id,
        };
      });
    },

    async insertRelationShip() {
      try {
        const isValid = await this.$refs.form.validate();
        if (!isValid) return;
        const confirm = await this.showConfirmSwal(
          `Are you sure you want to ${
            this.isEdit ? "edit" : "create"
          } this relationship?`
        );
        if (!confirm.isConfirmed) return;
        this.addPreloader();
        await ParagonService.insertRelationShipSection({
          section_id: this.itemEdit?.id || null,
          modules: this.selectedModules,
          section_name: this.section,
        });
        this.showSuccessSwal(
          `Relationship ${this.isEdit ? "edited" : "created"} successfully`
        );
        this.$emit("close", true);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
.available-modules {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.available-modules .custom-checkbox {
  flex: 0 0 49%;
  max-width: 49%;
}
</style>