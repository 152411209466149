const ownersTableFields = [
  {
    key: "name",
    label: "Engineer Name",
    sortable: false,
    visible: true,
    thStyle: {
      width: "20%",
    },
  },
  {
    key: "modules",
    label: "Modules Related",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "actions",
    label: "Actions",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
]

const sectionsTableFields = [
  {
    key: "name",
    label: "Section Name",
    sortable: false,
    visible: true,
    thStyle: {
      width: "20%",
    },
  },
  {
    key: "modules",
    label: "Modules Related",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: {
      maxWidth: "20%",
    },
  },
  {
    key: "actions",
    label: "Actions",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
]

const motivesRejectFields = [
  {
    key: "name",
    label: "Motive name",
    sortable: false,
    visible: true,
  },
  {
    key: "created_by",
    label: "Created By",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "created_at",
    label: "Created At",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
]

const titleSupportFields = [
  {
    key: "title",
    label: "Title Name",
    sortable: false,
    visible: true,
  },
  {
    key: "created_by",
    label: "Created By",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "created_at",
    label: "Created At",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
]

export {
  ownersTableFields,
  sectionsTableFields,
  motivesRejectFields,
  titleSupportFields,
}
